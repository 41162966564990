import * as React from "react";
import styled from "styled-components";


const Container = styled.div`
position: relative;
background-image: url(${p=>p.image});
background-size: cover;
display: flex;
flex-direction: column;
align-content: space-around;
justify-content: center;
align-items: center;
width: 100%;
height:${p => p.fixedh ?"400px":"90vh" };
@media (max-width: 900px) {
    height:230px;   
    margin-bottom: 40px;
}

`


const Overlay = styled.div`
width: 100%;
height: 100%;
background: linear-gradient(360deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 68.37%);
opacity:0.5;
z-index:2;
`

const Title = styled.div`
position: absolute;
display: flex;
justify-content: center;
z-index:4;

color:var(--blanco);
display: inline-flex;
font-size:48px;
font-weight:400;
left:50px;
bottom:20px;

@media (max-width: 900px) {
  font-size:19px;
  left:20px;
  font-weight:600;
}
`


const Herocol = (props) => {
    return(
    <Container image={props.imagen} fixedh={props.fixedheight}>   
    <Overlay>
      
      </Overlay>
      <Title>{props.title}</Title>
    </Container>
    )
    }
  
    export default Herocol;