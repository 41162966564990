import React from "react";
import { Link } from "gatsby";
import Home from "../images/Home/home.png";
import "../styles/breadcrums.css";
import styled from "styled-components";

const Breadcrumbs = styled.div`
  display: flex;
  flex-direction: row
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  flex-wrap: wrap;
  width: 90%;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Homeimg = styled.img`
  width: 32px;
`;

export const CustomBreadcrumb = ({ links, currentPage }) => {
  return (
    <Breadcrumbs>
      <Link
        to="/"
        className={"breadcrumb-not-active"}
        style={{ textDecoration: "none" }}
      >
        <Homeimg src={Home} alt="homeimage" />
      </Link>
      <span className="breadcrumb-arrow" style={{ color: "var(--celeste)" }}>
        &gt;
      </span>
      {links.map((link, index) => (
        <div key={index}>
          <Link
            to={link.to}
            className={"breadcrumb-active"}
            style={{ textDecoration: "none" }}
          >
            {link.name}
          </Link>
          <span
            className="breadcrumb-arrow"
            style={{ color: "var(--celeste)" }}
          >
            &gt;
          </span>
        </div>
      ))}
      <Link
        to={"#"}
        className={"breadcrumb-not-active"}
        style={{ textDecoration: "none" }}
      >
        {currentPage}
      </Link>
    </Breadcrumbs>
  );
};
